<template>
	<el-dialog title="快递详情" :visible.sync="dialogVisible" width="60%" @close="handleClose">
		<div class="express-detail">
			<!-- 地址信息 -->
			<!-- <div class="success-box">
				<el-input placeholder="请输入快递单号"></el-input>
				<el-button type="primary">查询</el-button>
			</div>
			
			<el-divider></el-divider> -->
			<div class="success-box">
				{{expressInfo.userData.consignee}}
			</div>
			<!-- 物流时间线 -->
			<el-timeline class="timeline-section">
				<el-timeline-item v-for="(process, index) in expressInfo" :key="index" :timestamp="process.acceptTime"
					:color="getTimelineColor(index)" placement="top">
					<div class="process-item">
						<span class="status">{{ process.acceptAddress }}</span>
						<span class="location">{{ process.remark }}</span>
					</div>
				</el-timeline-item>
			</el-timeline>
		</div>
	</el-dialog>
</template>
<script>
	export default {
		props: {
			visible: {
				type: Boolean,
				default: false
			},
			expressInfo: {
				type: Object,
				default: () => ({
					sender: {
						province: '',
						city: '',
						addressDetail: ''
					},
					receiver: {
						province: '',
						city: '',
						addressDetail: ''
					},
					processList: [],
					theme: localStorage.getItem('theme'),
				})
			}
		},
		computed: {
			dialogVisible: {
				get() {
					return this.visible
				},
				set(val) {
					this.$emit('update:visible', val)
				}
			}
		},
		methods: {
			handleClose() {
				this.$emit('close')

			},
			getTimelineColor(index) {
				// 第一个节点和最后一个节点用绿色，其他用蓝色
				// return index === 0 || index === this.expressInfo.processList.length - 1 ?
				// 	'#67C23A' :
				// 	'#409EFF'
				// 第一个节点，其他用蓝色
				return index === 0 ? '#67C23A' : '#ccc'
			}
		}
	}
</script>

<style scoped>
	.express-detail {
		padding: 20px;
	}

	.success-box {
		display: flex;
		flex: 1;

	}

	.timeline-section {
		margin-left: 40px;
	}

	.process-item {
		padding: 8px 16px;
		background: #f5f7fa;
		border-radius: 4px;
	}

	.status {
		font-weight: bold;
		margin-right: 15px;
		color: #303133;
	}

	.location {
		color: #606266;
	}

	.el-timeline-item__timestamp {
		margin-bottom: 8px;
	}
</style>