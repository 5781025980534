<template>
	<div>
		<div class="container">
			<div class="handle-box">
				<el-select v-model="query.agentId" @change="agentChange" clearable filterable placeholder="请选择代理商"
					size="small" class="handle-input mr10">
					<el-option v-for="item in agents" :key="item.value" :label="item.label"
						:value="item.value"></el-option>
				</el-select>
				<el-select v-model="query.companyId" clearable filterable placeholder="请选择企业" size="small"
					class="handle-input mr10">
					<el-option v-for="item in companys" :key="item.value" :label="item.label"
						:value="item.value"></el-option>
				</el-select>
				<el-select size="small" v-model="query.type" clearable filterable placeholder="请选择类型"
					class="mr10 w_130">
					<el-option v-for="item in typeList" :key="item.label" :label="item.label"
						:value="item.value"></el-option>
				</el-select>
				<el-select size="small" v-model="query.city" clearable filterable placeholder="请选择地市"
					class="w_130 mr10">
					<el-option v-for="item in cityList" :key="item.label" :label="item.city"
						:value="item.city"></el-option>
				</el-select>
				<el-select size="small" v-model="query.standard" clearable filterable placeholder="请选择制式"
					class="w_130 mr10">
					<el-option v-for="item in standardList" :key="item.label" :label="item.label"
						:value="item.value"></el-option>
				</el-select>
				<el-input size="small" v-model="query.iccid" clearable filterable placeholder="请输入ICCID"
					class="w_130 mr10 mt10"></el-input>
				<el-input size="small" v-model="query.imsi" clearable filterable placeholder="请输入IMSI"
					class="w_130 mr10 mt10"></el-input>
				<el-select size="small" v-model="query.cardId" clearable filterable placeholder="请选择品牌"
					class="w_130 mr10 mt10" @change="getSetMealList">
					<el-option v-for="item in cardTypeList" :key="item.label" :label="item.value"
						:value="item.label"></el-option>
				</el-select>
				<el-select size="small" v-model="query.setMealId" clearable filterable placeholder="请选择套餐"
					class="w_130 mr10 mt10">
					<el-option v-for="item in setMealIdList" :key="item.label" :label="item.name"
						:value="item.id"></el-option>
				</el-select>
				<el-select size="small" v-model="query.batchOpen" clearable filterable placeholder="是否批开"
					class="w_130 mr10 mt10">
					<el-option v-for="item in batchOpenList" :key="item.label" :label="item.label"
						:value="item.value"></el-option>
				</el-select>
				<el-select size="small" v-model="query.isReport" clearable filterable placeholder="是否绿名单"
					class="w_130 mr10 mt10">
					<el-option v-for="item in isReportList" :key="item.label" :label="item.label"
						:value="item.value"></el-option>
				</el-select>
				<el-input size="small" v-model="query.batchNum" clearable placeholder="请输入批次号"
					class="w_130 mr10 mt10"></el-input>
				<el-select size="small" v-model="query.operator" clearable filterable placeholder="请选择操作人"
					class="w_130 mr10 mt10">
					<el-option v-for="item in operatorList" :key="item.label" :label="item.operator"
						:value="item.operator"></el-option>
				</el-select>
				<el-date-picker v-model="timeArr" type="daterange" unlink-panels range-separator="至"
					start-placeholder="开始日期" end-placeholder="结束日期" @change="getTime()" size="small" class="mt10"
					style="width: 260px;margin-right: 10px;"></el-date-picker>
				<el-button size="small" type="primary" :class="[theme]" icon="el-icon-search"
					@click="getData">搜索</el-button>
			</div>
			<el-row :gutter="20" type="flex" class="row-bg">
				<el-col :span="24">
					<el-table :data="tableData" border class="table" ref="multipleTable" v-loading="loading"
						header-cell-class-name="table-header" :cell-style="{ cursor: 'pointer', padding: '6px' }"
						:row-style="{ height: '20px' }" :header-cell-style="{ 'text-align': 'center' }"
						:row-class-name="tableRowClassName" @selection-change="handleSelectionChange" height="500">
						<el-table-column type="selection" width="55" align="center"></el-table-column>
						<el-table-column prop="agent" label="代理商" align="center" width="180"></el-table-column>
						<el-table-column prop="company" label="企业" align="center" width="130"></el-table-column>
						<el-table-column prop="type" label="类型" align="center"></el-table-column>
						<el-table-column prop="phone" label="号码" align="center" width="120"></el-table-column>
						<el-table-column prop="cardType" label="运营商" align="center"></el-table-column>
						<el-table-column prop="uid" label="批次号" align="center" width="96"></el-table-column>
						<el-table-column prop="batchOpen" label="是否批开" align="center">
							<template #default="scope">
								<span v-if="scope.row.batchOpen==true">是</span>
								<span v-if="scope.row.batchOpen==false">否</span>
							</template>
						</el-table-column>

						<el-table-column prop="phoneStatus" label="号码状态" align="center"></el-table-column>
						<el-table-column prop="setMeal" label="套餐" align="center" width="150">
							<template #default="scope">
								<el-tooltip placement="top">
									<template #content>
										<div style="max-width: 300px; white-space: pre-wrap;"
											v-for='item in scope.row.setMealList'>{{item.min}}分钟{{item.price}}元</div>
									</template>
									<span>{{scope.row.setMeal}}</span>
								</el-tooltip>
							</template>
						</el-table-column>
						<el-table-column prop="standard" label="制式" align="center" width="80"></el-table-column>
						<el-table-column prop="isReport" label="绿名单" align="center">
							<template #default="scope">
								<span v-if="scope.row.isReport==true">是</span>
								<span v-if="scope.row.isReport==false">否</span>
							</template>
						</el-table-column>
						<el-table-column prop="logistics" label="快递单号" align="center" width="200">
							<template #default="scope">
								<span @click="showExpressDialog(scope.row)"
									style="color: #ff000f;cursor: pointer;">{{scope.row.logistics}}</span>
							</template>
						</el-table-column>
						<el-table-column prop="createTime" label="出库时间" align="center" width="180"></el-table-column>
						<el-table-column prop="operator" label="操作人" align="center"></el-table-column>
					</el-table>
				</el-col>
			</el-row>
			<div class="pagination">
				<el-pagination background layout="total, sizes, prev, pager, next, jumper"
					:current-page="query.pageIndex" :page-sizes="[30, 100, 200, 500]" :page-size="query.pageSize"
					:total="pageTotal" @size-change="handleSizeChange"
					@current-change="handlePageChange"></el-pagination>
			</div>
		</div>
		<el-dialog title="手动添加" v-model="addVisible" width="650px" :close-on-click-modal="false">
			<el-form :model="addForm" ref="addForm" label-width="120px">
				<el-form-item label="代理" prop="agent">
					<el-select v-model="addForm.agent" @change="companyChange" clearable filterable placeholder="请选择代理商"
						size="small" style="width: 100%;">
						<el-option v-for="item in agentsList" :key="item" :label="item" :value="item"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="企业" prop="companyId">
					<el-select v-model="addForm.companyId" clearable filterable placeholder="请选择企业" size="small"
						style="width: 100%;">
						<el-option v-for="item in companysList" :key="item" :label="item" :value="item"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="添加号码">
					<el-input size="small" type="textarea" :rows="5" placeholder="一行一个号码,一次最多添加100个号码"
						v-model="addForm.phones"></el-input>
				</el-form-item>
				<el-form-item style="display: flex;flex-direction: row;justify-content: end;">
					<el-button size="small" @click="addVisibleClose">取消</el-button>
					<el-button size="small" type="primary" :class="[theme]" @click="getAddReport">确定上传</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
		<express-detail-dialog v-model="dialogVisible" :express-info="expressData" @close="handleClose" />
	</div>
</template>

<script>
	import {
		getReportNumberList,
		deleteReportNumber,
		getReportCompany,
		getReportAgent,
		downloadReportNumber
	} from '../api/reportNumber.js';
	import {
		deliveryList,
		logisticsInquiry,
		setMealList,
		deliveryOperator,
		deliveryCity,
	} from '../api/SjtxCard.js';
	import { getCardType } from '../api/cardType.js';
	import ExpressDetailDialog from '../components/ExpressDetailDialog.vue'
	import { addReport, fetchCompanyByAgentId } from '../api/companyIndex.js';
	import { fetchAgent } from '../api/agentIndex';
	export default {
		name: 'outboundRecord',
		components: {
			ExpressDetailDialog
		},
		data() {
			return {
				theme: localStorage.getItem('theme'),
				query: {
					userId: localStorage.getItem('user'),
					agentId: '',
					companyId: '',
					cardId: '',
					city: '',
					standard: '',
					setMealId: '',
					batchOpen: '',
					isReport: '',
					batchNum: '',
					operator: '',
					iccid: '',
					imsi: '',
					pageIndex: 1,
					pageSize: 30,
				},
				timeArr: [],
				tableData: [],
				pageTotal: 0,
				multipleSelection: [],
				addForm: {
					phones: '',
				},
				addVisible: false,
				dialogVisible: false,
				expressData: [],
				agents: [],
				companys: [],
				standardList: [{
						value: '1',
						label: '联通',
					},
					{
						value: '2',
						label: '电信',
					},
					{
						value: '3',
						label: '移动',
					}
				],
				batchOpenList: [{
						value: true,
						label: '是',
					},
					{
						value: false,
						label: '否',
					}
				],
				isReportList: [{
						value: true,
						label: '是',
					},
					{
						value: false,
						label: '否',
					}
				],
				typeList: [{
						value: '1',
						label: '新增',
					},
					{
						value: '2',
						label: '换卡',
					},
					{
						value: '3',
						label: '补卡',
					}
				],
				operatorList: []
			};
		},
		created() {
			this.getTime();
			fetchAgent(localStorage.getItem('user')).then(res => {
				//代理
				this.agents = res.data;
			});
			this.getData();
			this.getSetMealList();
			this.getDeliveryOperator();
			this.getDeliveryCity();
			this.getCardType();
		},
		methods: {
			//获取时间
			getTime() {
				let startTime = new Date(new Date());
				let endTime = new Date();
				this.timeArr.push(startTime);
				this.timeArr.push(endTime);
			},
			//获取代理商
			agentChange(agentId) {
				this.query.agentId = agentId;
				this.query.companyId = '';
				//代理商下拉
				if (agentId) {
					let data = {
						subAgent: JSON.parse(localStorage.getItem('subAgent').toLowerCase()),
						agentId: agentId
					};
					fetchCompanyByAgentId(data).then(res => {
						this.companys = res.data;
					});
				}
			},
			tableRowClassName({ rowIndex }) {
				if ((rowIndex + 1) % 2 === 0) {
					return 'oddRow';
				}
				return 'evenRow';
			},
			getData() {
				deliveryList(this.query).then(res => {
					if (res.code == 200) {
						this.tableData = res.data.list;
						this.pageTotal = res.data.total;
					} else {
						this.$message.error(res.message);
					}
				});
			},
			//品牌
			getCardType() {

				getCardType().then(res => {
					this.cardTypeList = res.data;
				})
			},
			//套餐
			getSetMealList() {
				this.query.setMealId = '';
				let data = {
					cardId: this.query.cardId,
				};
				setMealList(data).then(res => {
					if (res.code == 200) {
						this.setMealIdList = res.data;
						console.log(this.setMealIdList, '我是赋值之后')
					} else {
						this.$message.error(res.message);
					}
				});
			},
			//操作人
			getDeliveryOperator() {
				deliveryOperator().then(res => {
					if (res.code == 200) {
						this.operatorList = res.data;
					} else {
						this.$message.error(res.message);
					}
				});
			},
			//城市
			getDeliveryCity() {
				deliveryCity().then(res => {
					if (res.code == 200) {
						this.cityList = res.data;
					} else {
						this.$message.error(res.message);
					}
				});
			},
			//弹窗
			showExpressDialog(row) {
				this.dialogVisible = true;
				let data = {
					id: row.id,
				}
				logisticsInquiry(data).then(res => {
					if (res.code == 200) {
						this.expressData = res.data;
						this.expressData.userData = row;
					} else {
						this.$message.error(res.message);
					}
				});
			},
			handleClose() {
				console.log('弹窗已关闭')
			},
			// 多选操作
			handleSelectionChange(val) {
				this.multipleSelection = val;
			},
			// 分页导航
			handlePageChange(val) {
				this.query.pageIndex = val;
				this.getData();
			},
			//下一页
			handleSizeChange(val) {
				this.query.pageSize = val;
				this.getData();
			},

		}
	};
</script>

<style scoped>
	.handle-box {
		margin-bottom: 50px;
	}

	/* a:hover{background: #66b1ff} */
	.handle-select {
		width: 240px;
	}

	.table {
		width: 100%;
		font-size: 12px;
	}

	.w_130 {
		width: 130px;
	}
</style>